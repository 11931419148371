
import Vue from "vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
//@ts-ignore
import { getPermisionNotification } from "@/utils/permissionResolve.ts";
import { mapGetters } from "vuex";

const EMAIL = "email_Act";
const NOTIFY = "notify_act";

export default Vue.extend({
	data() {
		return {};
	},
	mounted() {},
	components: { CardSwitch },
	computed: {
		...mapGetters("profile", ["getAbility"]),
		email_notification_active: {
			get() {
				return this.params.getValue();
			},
			set(val) {
				this.params.setValue(val);
				this.params.toggle(this.params.data.code, this.params.data.platform_notification_active, val);
			},
		},
		platform_notification_active: {
			get() {
				return this.params.getValue();
			},
			set(val) {
				this.params.setValue(val);
				this.params.toggle(this.params.data.code, val, this.params.data.email_notification_active);
			},
		},
		isTypeEmail(){
			return this.params.type == EMAIL;
		},
		isTypeNotify(){
			return this.params.type == NOTIFY;
		},
		getPermission(){
			return getPermisionNotification();
		},

		canUpdate(){
			return this.getAbility.can(this.getPermission.actions.update, this.getPermission.subject);
		}
	},
	methods: {
		getColor(active: Boolean) {
			return active ? "green--text" : "red--text";
		},
		getActiveText(active: Boolean) {
			return active
				? i18n.t("common.fields.active")
				: i18n.t("common.fields.inactive");
		},
	},
	watch: {},
});
