import Vue from "vue";
import Buttons from "@/views/Admin/Commons/Buttons.vue";
import SnackBar from "@/views/Admin/Commons/SnackBar.vue";
import { mapActions, mapGetters } from "vuex";
import { head } from "lodash";
import { Setting } from "@/interfaces/notification";
import { AgGridVue } from "ag-grid-vue";
import Toggle from "@/views/Admin/Notifications/List/toggle.vue";
import { TypeLoading } from "@/interfaces/loading";
import i18n from "@/plugins/i18n";
import CustomNoRowsOverlay from "@/components/Header/Tables/CustomNoRowsOverlay.vue";

const PINNED = {
	LEFT: "left",
	RIGHT: "right",
	EMPTY: "",
};

export default Vue.extend({
	name: "NotificationsIndex",
	model: {},
	props: {},
	components: {
		Buttons,
		SnackBar,
		AgGridVue,
		btnToggle: Toggle,
		CustomNoRowsOverlay,
	},
	data: () => ({
		columnDefs: null,
		rowData: null,
		enableSorting: true,
		enableFilter: false,
		rowDragManaged: false,
		rowSelection: "multiple",
		domLayout: "autoHeight",
		width: "100%",
		maxHeight: "600px",
		rowHeight: 60,
		noRowsOverlayComponent: null,

		gridApi: {},
		columnApi: {},
		defaultColDef: {
			flex: 1,
			editable: false,
			sortable: true,
			resizable: true,
			filter: false,
			minWidth: 130,
			draggable: true,
			rowDrag: false,
			lockPinned:true,
		},
	}),
	created() {
		this.$nextTick(async () => {
			this.noRowsOverlayComponent = "CustomNoRowsOverlay";
			await this.getTableData();
		});
	},
	async mounted() {},
	computed: {
		...mapGetters("internationalization", ["getLanguage"]),
		getSize() {
			return `show ${this.rowData?.length || 0} notifications`;
		},
	},
	methods: {
		...mapActions("notification", ["fetchSettings", "setActive"]),
		...mapActions("loading", ["setLoadingData"]),

		onGridReady(params: any) {
			this.gridApi = params.api;
			this.gridColumnApi = params.columnApi;
			this.gridApi.setHeaderHeight(50);
			this.gridApi.sizeColumnsToFit();
		},

		async getTableData() {
			try {
				const settings: any[] = await this.fetchSettings();
				this.columnDefs = await this.prepareHeaderFromResult(settings);
				this.rowData = await this.prepareItemsFromResult(settings);
			} catch (error) {
				console.log({ERROR: error});
			}
		},

		async prepareHeaderFromResult(settings: Setting[]) {
			let self = this;
			const setting = head(settings);

			if (!setting) return [];
			//console.log(setting);
			const keys = Object.keys(setting);

			let headers: any[] = keys.map((k) => {
				const textLang = `notifications.fields.${k}`;
				const descriptionColumn =
					textLang === "notifications.fields.description";
				const nameColumn = textLang === "notifications.fields.name";
				return {
					headerName: self.$t(textLang),
					field: k,
					width: self.getWidth(k),
					pinned: self.getPinned(k),
					cellStyle: {
						whiteSpace: descriptionColumn
							? "normal"
							: "nowrap" || nameColumn
							? "normal"
							: "nowrap", // Permite el ajuste del contenido en múltiples líneas solo para la columna de descripción
					},
					cellClass: descriptionColumn ? "overflow-y-auto" : "",
					cellRenderer:
						k == "platform_notification_active" ||
						k == "email_notification_active"
							? "btnToggle"
							: undefined,
					cellRendererParams: {
						type:
							k == "platform_notification_active"
								? "notify_act"
								: "email_Act",
						toggle: async function (
							code: string,
							platform_notification_active: Boolean,
							email_notification_active: Boolean
						) {
							try {
								self.setLoadingData(TypeLoading.loading);
								const result = await self.setActive({
									code,
									platform_notification_active,
									email_notification_active,
								});
								self.setLoadingData();
								await self.getTableData();
							} catch (error) {
								/*
								console.error(
									"cellRendererParams:setActive",
									error
								);*/
								self.setLoadingData();
								await self.getTableData();
							}
						},
					},
				};
			});

			return headers;
		},

		async prepareItemsFromResult(settings: Setting[]) {
			if (!settings) return [];
			const items: any[] = [];

			settings.map((setting) => {
				let item = {};
				for (const [key, value] of Object.entries(setting)) {
					if (key === "description") {
						const textLang = i18n.t(
							`notifications.table.${setting.name}`
						);
						item[key] = textLang;
					} else {
						item[key] = value;
					}
				}
				items.push(item);
			});
			return items;
		},

		getWidth(k: string) {
			if (k == "active" || k == "code") return 80;
			return 350;
		},

		getPinned(k: string) {
			if (k == "code") return PINNED.LEFT;
			if (k == "active") return PINNED.RIGHT;
			return PINNED.EMPTY;
		},
		async handleResetColumns() {
			await this.getTableData({ view: this.$route?.name, isReset: true });
		},
	},
	watch: {
		async getLanguage(lang) {
			await this.handleResetColumns();
			this.$forceUpdate();
		},
	},
});
